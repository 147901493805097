import React from "react"
import { graphql, Link } from "gatsby"
import { uniqBy } from "lodash"
import Reveal from "react-reveal/Reveal"
import GA from "../components/ga"
import Hero from "../components/hero"
import InnerWrapper from "../components/innerWrapper"
import Layout from "../components/layout"
import SEO from "../SEO"
import { slugify } from "../util/utilityFunctions"
import styles from "./scss/blog.module.scss"


class blog extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      initialData: this.props.data.allContentfulBlogPost.edges,
      data: this.props.data.allContentfulBlogPost.edges,
      slogan: this.props.data.contentfulBlogPageHeroSlogan.slogan,
      activeItem: 999,
    }
  }

  filter = (str, index) => {
    this.setState({ activeItem: index })
    const data = this.state.initialData

    if (str === "All") {
      this.setState({ data: data })
    } else {
      let filtered = data.filter(post => {
        return post.node.category === str
      })
      this.setState({ data: filtered })
    }
  }
  render() {
    const { data } = this.state
    const { initialData } = this.state

    return (
      <>
        <GA />
        <Layout>
          <SEO
            title="Articles"
            pathname="/articles"
            description={
              this.props.data.contentfulSeo.articlesPageSeoDescription
            }
          />
          <Hero slogan={this.state.slogan} cta={false} />
          <section className={styles.posts} id="article">
            <InnerWrapper width={1440}>
              <div className={styles.filterContainer}>
                <span>Filter:</span>
                <div className={styles.filters}>
                  <button
                    onClick={() => this.filter("All", 999)}
                    className={
                      this.state.activeItem === 999 ? styles.active : ""
                    }
                    type="button"
                  >
                    All
                  </button>
                  {uniqBy(initialData, "node.category").map((edge, index) => {
                    return (
                      <button
                        className={
                          this.state.activeItem === index ? styles.active : ""
                        }
                        key={index}
                        type="button"
                        onClick={() =>
                          this.filter(`${edge.node.category}`, index)
                        }
                      >
                        {edge.node.category}
                      </button>
                    )
                  })}
                </div>
              </div>
              <div className={styles.container}>
                {data.map((edge, index) => {
                  console.log(slugify(edge.node.title))
                  return (
                    <Reveal effect="fadeIn">
                      <Link to={`/article/${slugify(edge.node.title)}`}>
                        <article key={index} className={styles.post}>
                          <picture>
                            <source
                              srcSet={edge.node.image.fluid.srcWebp}
                              type="image/webp"
                            />
                            <source
                              srcSet={edge.node.image.fluid.src}
                              type="image/png"
                            />
                            <img
                              src={edge.node.image.fluid.src}
                              alt={edge.node.image.description}
                            />
                          </picture>
                          <div>
                            <p className={styles.category}>
                              {edge.node.category}
                            </p>
                            <h3 className={styles.title}>{edge.node.title}</h3>
                            {/* <p className={styles.date}>
                              {edge.node.publishDate}
                            </p> */}
                          </div>
                        </article>
                      </Link>
                    </Reveal>
                  )
                })}
              </div>
            </InnerWrapper>
          </section>
        </Layout>

      </>
    )
  }
}

export const query = graphql`
  query BlogQuery {
    contentfulSeo {
      articlesPageSeoDescription
    }
    contentfulBlogPageHeroSlogan {
      slogan
    }
    allContentfulBlogPost(sort: { fields: publishDate, order: ASC }) {
      totalCount
      edges {
        node {
          title
          category
          publishDate(formatString: "MMMM Do, YYYY")
          image {
            description
            fluid {
              src
              srcWebp
            }
          }
        }
      }
    }
  }
`
export default blog
